<template>
    <div class="marketing">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "marketing",

    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.marketing {
    height: 100%;
}
</style>